<template>
	<div class="router-view-wrapper" v-if="show_trigger">
		<SuccessErrorMessage :message="getMessage()" />
		<div class="data-wrapper">
			<q-item tag="label">
				<q-item-section>
					<q-item-label class="text-left">Do you want to trigger another message?</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle
						v-model="is_scenario_triggered"
					/>
				</q-item-section>
			</q-item>
			<div v-if="is_scenario_triggered">
				<div class="flex padding justify-between items-center">
					<span>
						<q-btn
							flat
							color="primary"
							label="Select template"
							@click="displayScenarioModal()"
						/>
						<span v-if="triggered_scenario.name">{{triggered_scenario.name}}</span>
					</span>
					<q-btn
						v-if="triggered_scenario.name"
						flat
						round
						color="primary"
						class="col-1"
						@click="removeScenario"
						icon="delete"
					/>
				</div>
				<q-dialog v-model="show_scenario_modal">
					<DisplayScenariosModal
						:number_of_pages="number_of_pages"
						@change-page="changePage"
						@select-scenario="selectScenario"
						@close="show_scenario_modal = false"
					/>
				</q-dialog>
				<q-input
					class="padding"
					type="number"
					label="How many minutes interval should there be after the first message is sent?"
					v-model="trigger_time"
					hint="The value must be in range between 1 and 30 minutes."
					:rules="[ val => (1 <= val && val <= 30) || 'The value must be in range between 1 and 30 minutes' ]"
					onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DisplayScenariosModal from "@/components/utils/DisplayScenariosModal.vue";
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";

export default {
	name: "MessageFormTrigger",
	components: {
		DisplayScenariosModal,
		SuccessErrorMessage,
	},
	data() {
		return {
			show_scenario_modal: false,
			page_number: 1,
			page_size: 10,
			items_per_page: 10,
			show_trigger: false
		};
	},
	computed: {
		...mapGetters([
			"errors_per_tab",
			"total_number_of_scenarios",
			"current_CG",
			"logged_user"
		]),
		triggered_scenario: {
			get() {
				return this.$store.getters.triggered_scenario;
			},
			set(val) {
				this.updateTriggeredScenario(val);
			}
		},
		trigger_time: {
			get() {
				return this.$store.getters.trigger_time;
			},
			set(val) {
				this.updateTriggerTime(val);
			}
		},
		number_of_pages: function() {
			return Math.ceil(this.total_number_of_scenarios / this.items_per_page);
		},
		is_scenario_triggered: {
			get() {
				return this.$store.getters["trigger_scenario"]
			},
			set(val) {
				if (!val) {
					this.updateTriggerTime(0);
					this.updateTriggeredScenario({});
					this.updateTriggerScenario(false);
				}
				this.updateTriggerScenario(val);
			}
		}
	},
	methods: {
		...mapActions([
			"updateTriggerTime",
			"updateTriggeredScenario",
			"updateTriggerScenario",
			"listScenariosForModal"
		]),
		async displayScenarioModal() {
			await this.listScenarios();
			this.show_scenario_modal = true;
		},
		selectScenario(scenario) {
			this.updateTriggeredScenario(scenario);
			this.show_scenario_modal = false;
		},
		removeScenario() {
			this.updateTriggeredScenario({});
		},
		getMessage() {
			const errors = this.errors_per_tab["Trigger"];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		async listScenarios() {
			await this.listScenariosForModal({
				sessions_flags: false,
				scheduled_flag: false,
				page_number: this.page_number,
				page_size: this.page_size,
				active_tabs: "all",
				is_trigger: true,
				triggering_scenario: this.$route.params.id
			});
		},
		changePage(page_number) {
			this.page_number = page_number;
			this.listScenarios();
		}
	},
	created() {
		this.show_trigger =
			this.current_CG.root_customer_group_permissions.includes(
				"Trigger Message"
			) && this.logged_user.customer_permission != "SENDER";
	}
};
</script>
